import { loadingController, toastController } from '@ionic/vue';
import { checkmarkCircleOutline, warningOutline, closeCircleOutline } from 'ionicons/icons';
import moment from 'moment';
import axios from 'axios';
import { version } from '../../package.json';
import { currentUserStore } from '@/stores/currentUser';

export default function tools() {

  const loading = loadingController.create({});



  const toast = async (message: string, color: 'success' | 'warning' | 'danger', duration = 2500) => {
    let _color = 'succcess' // por defecto
    switch (color) {
      case 'success': _color = checkmarkCircleOutline; break;
      case 'warning': _color = warningOutline; break;
      case 'danger': _color = closeCircleOutline; break;
    }
    const _toast = await toastController.create({
      message: message,
      duration: duration,
      position: 'top',
      color: color,
      icon: _color,
    });

    await _toast.present();
  }

  const delay = async (milliseconds: number) => {
    return new Promise(resolve => {
      setTimeout(resolve, milliseconds);
    });
  }

  const getImageClase = (src: string): string => {
    if (('' + src).includes('href'))
      return src
    else
      return process.env.VUE_APP_MEDIA_URL + 'users/' + src

  }

  const getPathImage = (src: string): string => {
    if (('' + src).includes('href'))
      return src
    else
      return process.env.VUE_APP_MEDIA_URL + src

  }


  const showLoading = async (msj: string) => {
    (await loading).message = msj;
    (await loading).present();
  }

  const hideLoading = async () => { (await loading).dismiss() }

  const getYtURLVideo = (url: string) => {
    if (url.includes('youtube'))
      return url.split('v=')[1]
    else if (url.includes('youtu.be'))
      return url.split('.be/')[1]
    else
      return false
  }

  //comprueba si tiene el permiso en currentUserStore.permissions
  const can = (permiso: string): boolean => {
    const user = currentUserStore().$state.user;
    if (user === null || user === undefined) return false;
    return user.permissions.includes(permiso);
  }

  const number2day = (numberDay: number): string => {
    switch (numberDay) {
      case 1: return 'Lunes';
      case 2: return 'Martes';
      case 3: return 'Miercoles';
      case 4: return 'Jueves';
      case 5: return 'Viernes';
      case 6: return 'Sábado';
      case 7: return 'Domingo';
      default: return 'undefined';
    }
  }

  const relativeDate = (myDate: Date): string => {
    if (moment(myDate).format('YMD') == moment().format('YMD')) return 'hoy';
    else if (moment(myDate).format('YMD') == moment().add(1, 'days').format('YMD')) return 'mañana';
    else if (moment(myDate).format('YMD') == moment().add(2, 'days').format('YMD')) return 'pasado mañana';
    else if (moment(myDate).format('YMD') == moment().add(-1, 'days').format('YMD')) return 'ayer';
    else if (moment(myDate).format('YMD') == moment().add(-2, 'days').format('YMD')) return 'antes de ayer';
    else if (moment(myDate).diff(moment(), 'days') < 7) return 'este ' + moment(myDate).format('dddd');
    else if (moment(myDate).diff(moment(), 'days') >= 7) return ' el próximo ' + moment(myDate).format('dddd') + ' ' + moment(myDate).format('d')
    else return moment(myDate).format('dddd d') + ' de ' + moment(myDate).format('mmmm')
  }

  const setLog = (event: string, level?: 'warning' | 'info' | 'error' | 'debug') => {
    try {
      axios.post('log/set', {
        event: event,
        level: level === undefined ? 'info' : level,
        version: 'galigest_' + version
      })
    } catch (error) {
      console.log('No se pudo guardar el log en el servidor para el evento -' + event + '-')
    }
  }

  /*
  * Devuelve el nombre de un usiario contemplando que la primera palabra es el nombre
  */
  const getShortName = (name: string): string => {
    if (name === '' || name === null || name === undefined) return name
    //si no tiene espacios, devolvemos el nombre completo
    if (!name.includes(' ')) return name
    const names = name.split(' ')
    if (names.length > 1)
      return names[0]
    else
      return name
  }

  /*
  * Devuelve la hora en formato corto sin ceros a la izquierda
  */
  const getShortHour = (hour: string): string => {
    if (hour === '' || hour === null || hour === undefined) return hour

    if (hour === '' || !hour.includes(':') || hour === null || hour === undefined) {
      return hour
    }

    const h = hour.split(':')
    if (h.length > 1 && h[1] === '00')
      return h[0] + 'h'
    else
      return h[0] + ':' + h[1] + 'h'
  }

  const getTlfConPrefijo = (tlf: string): string => {
    if (tlf.startsWith('+3400')) return '+' + tlf.substring(5)
    if (tlf.startsWith('6') || tlf.startsWith('7')) return '+34' + tlf;

    if (tlf === '' || tlf === null || tlf === undefined) return tlf
    if (tlf.includes('+34')) return tlf
    else return tlf
  }

  const number2Level = (level: number): string => {
    switch (level) {
      case 0: return 'Básico 1';
      case 1: return 'Básico 2';
      case 2: return 'Intermedio 1';
      case 3: return 'Intermedio 2';
      case 4: return 'Intermedio 3';
      case 5: return 'Avanzado';
      default: return 'Sin definir';
    }
  }

  return {
    toast, delay, getImageClase, number2day, relativeDate, getYtURLVideo, setLog, getPathImage, showLoading, can, hideLoading, getShortName, getShortHour, getTlfConPrefijo, number2Level
  }
}