import {defineStore} from "pinia";
import { Aula } from "@/interfaces/Aula";
import tools from '@/composables/tools';
import axios from 'axios';

export const useAulaStore = defineStore("aulas", {
    state: () => ({
        aulas: [] as Aula[],
    }),

    getters: {
        getByName: (state) => (name: string) => {
            return state.aulas.find(aula => aula.nombre === name)
        },

        getActive: (state) => () => {
            return state.aulas.filter(aula => aula.activo === true)
        },

        getAllByAlias: (state) => (alias: string) => {
            return state.aulas.filter(aula => aula.alias === alias)
        }
    },

    actions: {
        async updateAulas() {
            try {
                const response = await axios.get("aulas?page=0&filter[activo]=true")
                console.log(response.data.data)
                this.aulas = response.data.data
            } catch (error) {
                tools().toast('No se pudieron cargar las aulas', 'danger', 5000);
                tools().setLog('Error al cargar las aulas', 'error');

            }
        }
    }
})
